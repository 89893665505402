//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/hotel-city-selector/1.1.0/index.vue";
import moment from "moment";

export default {
  name: "TravelBoxHotel",
  data() {
    return {
      formData: {
        startDate: '',
        endDate: '',
      },
      ruleDate: [],
      startPickerOptions: {
        disabledDate: time => {
          if (this.getDate(0)) {
            return (
              time.getTime() < this.getDate(0) ||
              time.getTime() > this.getDate(1)
            );
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24;
          }
        }
      },
      endPickerOptions: {
        disabledDate: time => {
          const ruleDate = this.ruleDate;
          const rangeStart = ruleDate[0];
          const rangeEnd = ruleDate[1];
          const end = moment(time);
          const start = moment(this.formData.startDate);
          const now = moment();
          const diff = end.diff(start, 'days') < 0;
          const diffStart = end.diff(rangeStart, 'days') <= 0;
          const diffEnd = end.diff(rangeEnd, 'days') > 0;
          if (diff || diffStart || diffEnd) return true;
          // console.log(this.getDate(0), this.getDate(1), diff)
          // if (this.getDate(0)) {
          //   return (
          //     time.getTime() < this.getDate(0) ||
          //     time.getTime() > this.getDate(1)
          //   );
          // } else {
          //   if (time.getTime() < Date.now() - 1000 * 3600 * 24 || diff <= 0) return true;
          //   return time.getTime() < Date.now() - 1000 * 3600 * 24 || diff <= 0;
          // }

        }
      },
      rules: {
        startCity: [
          { required: true, message: "请输入出发地", trigger: "change" }
        ],
        startDate: [
          { required: true, message: "请选择出发日期", trigger: "change" }
        ],
        endDate: [
          { required: true, message: "请选择返程日期", trigger: "change" }
        ]
      }
    };
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    CitySelector
  },
  inject: ["journeyDete"],

  methods: {
    getDate(index) {
      if (this.ruleDate.length > 0) {
        return moment(this.ruleDate[index]).valueOf();
      } else {
        return false;
      }
    },
    change_startDate (val) {
      if (!val) return;
      this.formData.endDate = moment(val).add(1, 'days').format('YYYY-MM-DD');
      this.change_endDate();
      this.$refs.endDate.focus()
    },
    change_endDate (val) {
      this.$emit('input', this.formData)
    }
  },
  created() {
    console.log(this.journeyDete)
    this.ruleDate = this.journeyDete.date || [];
  },
  activated() {},
  watch: {
    value: {
      handler (val) {
        this.formData = val;
      },
      deep: true,
    },
    journeyDete: {
      handler (val) {
        console.log(val);
      },
      deep: true,
    }
  },
  computed: {}
};
