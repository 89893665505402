//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddTravelBox from "./addTravelBox";
export default {
  name: "AddTravel",
  data() {
    return {
      isShow: false,
      travelList: []
    };
  },
  components: { AddTravelBox },
  methods: {
    setShow() {
      this.isShow = !this.isShow;
    },
    deleteTravel(index) {
      this.travelList.splice(index, 1);
    },
    getItem(val) {
      this.travelList.push(val);
      this.setShow();
    }
  },
  created() {},
  activated() {},
  watch: {
    travelList(val) {
      console.log("出差行程", val);
      this.$emit("input", val);
    }
  },
  computed: {}
};
