//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue";
import index from "../../../myOrder/flightOrder";
import moment from "moment";
export default {
  name: "TravelBoxFilgth",
  data() {
    return {
      formData: this.value,
      startPickerOptions: {
        disabledDate: time => {
          if (this.getDate(0)) {
            return (
              time.getTime() < this.getDate(0) ||
              time.getTime() > this.getDate(1)
            );
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24;
          }
        }
      },
      endPickerOptions: {
        disabledDate: time => {
          if (this.formData.startDate && this.getDate(0)) {
            if (this.getDate(0)) {
              return (
                time.getTime() < new Date(this.formData.startDate).valueOf() ||
                time.getTime() > this.getDate(1)
              );
            } else {
              return (
                time.getTime() < new Date(this.formData.startDate).valueOf()
              );
            }
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24;
          }
        }
      },
      ruleDate: [],
      rules: {
        startCity: [
          { required: true, message: "请输入出发地", trigger: "change" }
        ],
        endCity: [
          { required: true, message: "请输入目的地", trigger: "change" }
        ],
        startDate: [
          { required: true, message: "请选择出发日期", trigger: "change" }
        ],
        endDate: [
          { required: true, message: "请选择返程日期", trigger: "change" }
        ]
      }
    };
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    CitySelector
  },
  inject: ["journeyDete"],
  methods: {
    exchange() {
      let copyStartCity = this.formData.startCity;
      this.formData.startCity = this.formData.endCity;
      this.formData.endCity = copyStartCity;
    },
    getDate(index) {
      if (this.ruleDate.length > 0) {
        return moment(this.ruleDate[index]).valueOf();
      } else {
        return false;
      }
    }
  },
  created() {
    this.ruleDate = this.journeyDete.date || [];
    // this.ruleDate.length ? (this.formData.startDate = this.ruleDate[0]) : "";
  },
  activated() {},
  watch: {},
  computed: {}
};
