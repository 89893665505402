//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import flight from "./travelBox-filght";
import train from "./travelBox-train";
import hotel from "./travelBox-hotel";

export default {
  name: "AddTravelBox",
  data() {
    return {
      active: null,
      fligthForm: {
        airType: 1
      },
      ruleFormName: null,
      ruleList: ["fligthForm", "trainForm", "hotelForm"],
      trainForm: {},
      hotelForm: {},
      formData: {},
      headNav: [
        {
          title: "机票",
          ico: "haolv-iconfont haolv-iconflight-icon"
        },
        {
          title: "火车",
          ico: "haolv-iconfont haolv-icontrain-icon"
        },
        {
          title: "酒店",
          ico: "haolv-iconfont haolv-iconhotel-icon"
        }
      ]
    };
  },
  props: {
    setShow: Function
  },
  components: {
    flight,
    train,
    hotel
  },
  methods: {
    submit() {
      let params = this[this.ruleList[this.active]];
      let _this = this;

      console.log(params);
      this.ruleFormName.validate(valid => {
        if (valid) {
          let data = {
            businessType: this.active + 1,
            departCityId:
              params.startCity.cityId ||
              params.startCity.stationCode ||
              params.startCity.code,
            departCityName:
              params.startCity.cityCn ||
              params.startCity.stationName ||
              params.startCity.name,
            departTime: params.startDate,
            returnTime: params.endDate
          };
          if (this.active !== 2) {
            (data.toCityId =
              params.endCity.cityId ||
              params.endCity.stationCode ||
              params.endCity.code),
              (data.toCityName =
                params.endCity.cityCn ||
                params.endCity.stationName ||
                params.endCity.name);
          }
          _this.$emit("getItem", data);
        } else {
          console.error("error");
          return false;
        }
      });
    }
  },
  created() {},
  mounted() {
    this.active = 0;
  },
  activated() {
    console.log(8)
  },
  updated() {
    let index = this.active;
    let ruleList = this.ruleList;
    let ruleForm = this.$children[0];
    this.$nextTick(() => {
      this.ruleFormName = ruleForm.$refs[ruleList[index]];
    });
  },
  watch: {},
  computed: {}
};
