//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getDepartmentUsers from "@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers";
import allList from "@/lib/data-service/haolv-default/consumer_web_costCenter_allList";

export default {
  name: "SelectDepartment",
  data() {
    return {
      headNav: ["组织架构", "成本中心"],
      active: 0,
      data: [],
      metaNodeCheckedId: [],
      allCostList: [],
      costActive: "",
      defaultProps: {
        children: "deptList",
        label: "deptName"
      }
    };
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getCheckedNodes(data, checked) {
      let checkList = this.$refs.tree.getCheckedNodes();
      this.updataValue(checkList);
      if (checked) {
        this.disabledChecked(this.data, true, checkList[0]);
      } else {
        this.disabledChecked(this.data, false);
      }
      this.reset();
    },
    disabledChecked(list, status, checkList) {
      list.map((item, index) => {
        if (checkList) {
          checkList.deptId !== item.deptId ? (item.disabled = status) : "";
        } else {
          item.disabled = status;
        }
        if (item.deptList.length > 0) {
          return this.disabledChecked(item.deptList, status, checkList);
        }
      });
      setTimeout(() => {
        this.data = list;
        checkList
          ? this.$refs.tree.setCheckedKeys([checkList.deptId])
          : this.$refs.tree.setCheckedKeys([]);
      });
    },
    _getDepartmentUsers() {
      getDepartmentUsers().then(res => {
        let data = res.datas;
        data.unshift({
          deptId: -1,
          deptName: '各自所在部门',
          deptList: [],
        })
        this.data = data;
        this.reset();
      });
    },
    reset() {
      this.data.map(item => {
        if (item.deptList.length > 0) {
          item.disabled = true;
        }
      });
    },
    setActive(index) {
      this.active = index;
      // this.value.length === 0 ? (this.active = index) : "";
    },
    getActive(index, val) {
      this.costActive === index
        ? ((this.costActive = ""), this.updataValue([]))
        : ((this.costActive = index), this.updataValue([val]));
    },
    updataValue(val) {
      this.$emit("input", val);
      this.$emit("feeAffiliationType", this.active + 1);
    },
    _allList() {
      allList().then(res => {
        this.allCostList = res.datas;
      });
    },
    init() {
      this._allList();
      this._getDepartmentUsers();
    }
  },
  created() {
    this.init();
  },
  activated() {},
  watch: {},
  computed: {}
};
