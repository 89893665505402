//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TimeLine",
  data() {
    return {};
  },
  props: {
    activities: {
      type: Array,
      default: () => {
        return [
          {
            content: "发起申请",
            color: "#ff6f21"
          },
          {
            content: "审批同意"
          },
          {
            content: "预定机票、火车票、酒店"
          },
          {
            content: "出行"
          }
        ];
      }
    }
  },
  methods: {},
  created() {},
  activated() {},
  watch: {},
  computed: {}
};
