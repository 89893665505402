//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.1.0/index.vue";
import moment from "moment";

export default {
  name: "TravelBoxTrain",
  data() {
    return {
      formData: this.value,
      ruleDate: [],
      startPickerOptions: {
        disabledDate: time => {
          if (this.getDate(0)) {
            return (
              time.getTime() < this.getDate(0) ||
              time.getTime() > this.getDate(1)
            );
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24;
          }
        }
      },
      // endPickerOptions: {
      //   disabledDate: time => {
      //     if (this.formData.startDate) {
      //       return time.getTime() < new Date(this.formData.startDate).valueOf();
      //     } else {
      //       return time.getTime() < Date.now() - 1000 * 3600 * 24;
      //     }
      //   }
      // },
      rules: {
        startCity: [
          { required: true, message: "请输入出发地", trigger: "change" }
        ],
        endCity: [
          { required: true, message: "请输入目的地", trigger: "change" }
        ],
        startDate: [
          { required: true, message: "请选择出发日期", trigger: "change" }
        ],
        endDate: [
          { required: true, message: "请选择返程日期", trigger: "change" }
        ]
      }
    };
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    CitySelector
  },
  inject: ["journeyDete"],

  methods: {
    exchange() {
      let copyStartCity = this.formData.startCity;
      this.formData.startCity = this.formData.endCity;
      this.formData.endCity = copyStartCity;
    },
    getDate(index) {
      if (this.ruleDate.length > 0) {
        return moment(this.ruleDate[index]).valueOf();
      } else {
        return false;
      }
    }
  },
  created() {
    this.ruleDate = this.journeyDete.date || [];
    // this.ruleDate.length ? (this.formData.startDate = this.ruleDate[0]) : "";
  },
  activated() {},
  watch: {},
  computed: {}
};
