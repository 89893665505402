import AddForm from '../../components/addForm'

export default {
    data() {
        return {
        }
    },
    components: {
        AddForm
    }
}