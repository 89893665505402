import orderSearch from "../component/orderSearch";
import orderList from "@/lib/data-service/haolv-default/consumer_air_ticket_orderList";
import cancelOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";
import consumer_air_ticket_cancelChangePay from '@/lib/data-service/haolv-default/consumer_air_ticket_cancelChangePay'
import WarningBox from "../component/warningBox";
import { getWeekDay } from "@/lib/utils/common";
export default {
  data() {
    return {
      formData: {
        type: this.type,
        currentPage: 1,
        pageSize: 10,
        total: 0,
        orderStatus: "",
        evectionType: "",
      },
      evectionNo: "",
      orderNo: "",
      isShow: false,
      flightList: [],
      getWeekDay: getWeekDay,
      loading: false,
      pageLoading: false,
    };
  },
  components: {
    orderSearch,
    WarningBox,
  },
  created() {},
  mounted() {},
  activated() {
    this.getList();
  },
  props: {
    type: String, //0.机票订单 1.退票单 2.该签单 3.退款单
  },
  methods: {
    //退票
    refundTicket(orderNo) {
      this.orderNo = orderNo;
      this.refund(orderNo, 2);
    },
    goDetail(val, psgNoList) {
      let orderNo = val;
      var goPage = (name, psgNoList) => {
        this.$router.push({
          name: name,
          query: {
            orderNo: orderNo,
            psgNoList,
          },
        });
      };
      let type = this.type;
      switch (type) {
        case "0":
          this.$router.push({
            name: 'admin-my-order-flight-detail',
            query: {
              orderNo: val.orderNo
            }
          })
          break;
        case "1":
          this.$router.push({
            name: 'admin-my-order-flight-refund-detail',
            query: {
              orderNo: val.orderNo,
            }
          })
          break;
        case "2":
          this.$router.push({
            name: 'admin-my-order-flight-change-detail',
            query: {
              orderNo: val.changeOrderNo,
              psgNoList: val.psgNo,
              oriOrderNo: val.orderNo
            }
          })
          break;
        case "3":
          this.$router.push({
            name: 'admin-my-order-flight-refund-form-detail',
            query: {
              orderNo: val.orderNo
            }
          })
          break;
        default:
          break;
      }
    },
    cancelRefund() {},
    cancelChange(item) {
      this.$confirm("确定要取消订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let data = {
          changeNo: item.changeOrderNo
        };
        consumer_air_ticket_cancelChangePay(data).then(res => {
          this.getList()
        });
      }).catch(() => {});
    },
    payChange(item) {
      this.$router.push({
        name: "admin-pay",
        query: { orderNos: item.changeOrderNo, /*evectionNo: item.evectionNo,*/ orderType: 1, pageType: 3 },
      });
    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.getList();
    },
    /*setShow(orderNo, evectionNo) {
            this.isShow = true
            this.orderNo = orderNo
            this.evectionNo = evectionNo
        },*/
    cancel() {
      this.isShow = false;
    },
    goPay(orderNo) {
      this.$router.push({
        name: "admin-travel-payment",
        query: { orderNo: orderNo, orderType: 1 },
      });
    },
    cancelOrder(orderNo) {
      this.$confirm("确定要取消订单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
          .then(() => {
            let data = {
              orderNo
            };
            cancelOrder(data).then(res => {
              this.getList()
            });
          })
          .catch(() => {});
    },
    refund(orderNo, orderStatus, evectionNo) {
      // this.isShow = false
      this.$router.push({
        name: "admin-my-order-flight-refund",
        query: {
          orderNo: orderNo,
          orderStatus: orderStatus
        },
      });
    },
    ticket(orderNo, evectionNo, evectionType) {
      // this.isShow = false
      this.$router.push({
        name: "admin-my-order-flight-change",
        query: {
          orderNo: orderNo,
          evectionNo: evectionNo,
          evectionType: evectionType === '出差申请' ? 2 : 1
        },
      });
    },
    getList() {
      this.loading = true;
      orderList(this.formData)
        .then((res) => {
          this.loading = false;
          this.flightList = res.pageResult.pageData;
          this.formData.total = res.pageResult.totalCount;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getOrderStatu(type) {
      let statuList = this.$attrs.statuList;
      let list = statuList.filter((item) => {
        return item.value === type;
      });
      return list[0];
    },
    handleCurrentChange(val) {
      this.formData.currentPage = val;
      this.getList();
    },
  },
};
