//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TimeLine from "./timeLine";
import AddTravel from "./addTravel";
import Upload from "./uploadAccessory";
import Dialog from "@/component/dialog";
import SelectApprover from "./selectApprover";
import SelectCity from "@/component/selectCity";
import SelectDepartment from "./selectDepartment";
import CitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/travel-application-city-selector/1.0.0/index.vue";
import addEvection from "@/lib/data-service/haolv-default/consumer_apply_addEvection";
import matching from "@/lib/data-service/haolv-default/consumer_approveFlow_matching";
export default {
  name: "component_name",
  data() {
    return {
      // 已选中出行人
      travellerList: [],
      // 已选中审批人
      approvePeopleList: [],
      loading: false,
      approverSelectList: [],
      formData: {
        businessPeople: [],
        departmentList: []
      },
      startPickerOptions: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 1000 * 3600 * 24;
        }
      },
      addShow: true,
      approverType: "pageList",
      peopleList: [], //审批人
      newPeopleList: [],
      isShow: false,
      isFreeApprove: "",
      approverId: "",
      isDepartment: false,
      isDepartmentStatu: false,
      approverListShow: false,
      showStatu: false,
      rules: {
        businessPeople: [
          { required: true, message: "请选择出差人员", trigger: "change" }
        ],
        date: [{ required: true, message: "请选择日期", trigger: "change" }],
        city: [{ required: true, message: "请选择城市", trigger: "blur" }],
        reason: [
          { required: true, message: "请输入出差事由", trigger: "blur" }
        ],
        explainDesc: [
          { required: true, message: "请输入详情说明", trigger: "blur" }
        ],
        fileList: [
          { required: true, message: "请上传附件", trigger: "change" }
        ],
        journeyList: [
          { required: true, message: "请选择出差行程", trigger: "change" }
        ],
        departmentList: [
          { required: true, message: "请选择费用归属", trigger: "change" }
        ]
      }
    };
  },
  components: {
    TimeLine,
    AddTravel,
    Upload,
    Dialog,
    SelectApprover,
    SelectCity,
    SelectDepartment,
    CitySelector
  },
  methods: {
    close_traveller () {
      this.approverListShow = false;
      this.formData.businessPeople = [];
      this.travellerList = [];
    },
    commit_traveller () {
      this.formData.businessPeople = this.travellerList;
      this.approverListShow = false;
      this.$refs.applyForm.validateField('businessPeople');
    },
    cancel_traveller () {
      this.approverListShow = false;
      this.travellerList = this.formData.businessPeople;
    },
    close_node_dialog () {
      this.approvePeopleList = [];
      this.isShow = false;
    },
    commit_node_dialog () {
      this.isShow = false;
      this.newPeopleList = this.approvePeopleList;
    },
    cancel_node_dialog () {
      this.isShow = false;
      this.approvePeopleList = this.newPeopleList;
    },


    dateFocus () {
      this.$refs.addTravel.isShow = false;
    },
    approver(text, item) {
      item ? (this.approverId = item.relevanceId) : "";
      this.approverType = text;
      this.isShow = true;
    },
    submit() {
      const _this = this;
      let evectionCityName = this.$refs.evectionCity.$refs
          .aBaseMultipleEntitySelector.input,
        formData = this.formData;
      this.$refs.applyForm.validate(valid => {
        if (valid) {
          if (!this.isFreeApprove) {
            if (this.peopleList.length === 0)
              return this.$message({
                type: "warning",
                message: "请添加审批人！"
              });
          }
          let params = {
            toCityName: evectionCityName,
            toCityId: formData.city.join(","),
            accessoryUrl:
              formData.fileList && formData.fileList.length
                ? formData.fileList.join(",")
                : "",
            startDate: formData.date[0],
            endDate: formData.date[1],
            staffIdList: splitId(formData.businessPeople),
            feeAffiliationName: splitName(formData.departmentList).join(""),
            feeAffiliationId: Number(splitId(formData.departmentList).join("")),
            recordList: !this.isFreeApprove ? splitObj(this.peopleList) : null
          };
          let data = Object.assign(params, this.formData);
          this.loading = true;
          addEvection(data).then(res => {
            this.loading = false;
            this.$message({ type: "success", message: "申请出差成功" });
            this.$router.push({ name: "admin-my-apply-manage-apply" });
          });
        } else {
          return false;
        }
      });

      function splitId(list) {
        return list.map(item => item.id);
      }
      function splitName(list) {
        return list.map(item => item.deptName || item.centerName);
      }

      function splitObj(obj) {
        try {
          return obj.map((item, index) => {
            if (item.approverStatu) {
              if (item.sourceType === 1) {
                if (!item.approver) throw "error";
              }
              return {
                // approveStatus: item.approver.check,
                approverUserId: item.approver ? item.approver.id : -1,
                approverUserName: item.approver
                  ? item.approver.name
                  : item.nodeName,
                gmtCreate: item.gmtCreate,
                gmtModified: item.gmtModified,
                id: item.id,
                linkNum: index + 1,
                nodeName: item.nodeName
              };
            } else {
              return {
                approverUserId: item.id,
                linkNum: index + 1,
                approverUserName: item.name
              };
            }
          });
        } catch {
          _this.$message({
            type: "warning",
            message: "请添加审批人！"
          });
        }
      }
    },
    deletePeople(index) {
      this.peopleList.splice(index, 1);
      // this.approvePeopleList.splice(index, 1);
      this.approvePeopleList = [];
    },
    setShow(e) {
      if (!this.showStatu) {
        // this.approverListShow = false;
      }
      if (!this.isDepartmentStatu) {
        this.isDepartment = false;
      }
    },
    judgeObj(obj = {}, name) {
      if (obj.constructor === Object) {
        return name in obj;
      }
    }
  },
  created() {

  },
  mounted () {
    const _this = this;
    document.addEventListener("click", this.setShow, false);
  },
  activated () {
    const _this = this;
    document.addEventListener("click", this.setShow, false);
  },
  deactivated () {
    this.formData = {
      businessPeople: [],
      date: [],
      city: [],
      reason: '',
      explainDesc: '',
      feeAffiliationType: null,
      departmentList: [],
      journeyList: [],
    }

    this.newPeopleList = [];
    this.peopleList = [];
    this.$refs.addTravel.travelList = [];
  },
  beforeDestroy() {
    document.removeEventListener("click", this.setShow, false);
  },
  watch: {
    "formData.businessPeople": {
      handler: function(val) {
        if (val.length === 0) return (this.peopleList.length = 0);
        if (val.length > 9) {
          this.formData.businessPeople = val.splice(0, 9);
        }
        let data = {
          staffIds: val.map(item => item.id),
          applyType: 1 //审批类型：1.出差申请，2超标申请，3.报销申请
        };
        matching(data).then(res => {
          this.peopleList = res.datas.nodeList || [];
          this.isFreeApprove = res.datas.isFreeApprove;
          if (res.datas.nodeList.length) {
            this.addShow = false;
            this.peopleList.map(item => {
              item.approverStatu = true;
            });
          }
        });
      }
    },
    approverSelectList(val) {
      this.peopleList.map((item, index) => {
        if (val.length) {
          item.approver = val[val.length - 1];
        } else {
          item.approver = {};
        }
      });
    },
    newPeopleList(val) {
      if (val.length > 1) {
        this.peopleList = [val[0]];
        this.newPeopleList = this.peopleList;
        this.$message({ type: "warning", message: "只能选择一位审批人" });
      } else {
        this.peopleList = val;
      }
    }
  },
  provide() {
    return {
      journeyDete: this.formData
    };
  },
  computed: {}
};
